@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
hr {
  border-color: rgba(255, 255, 255, 0.2);
}
html {
  background: #060a1b;
  background: url("../public/bg.webp") no-repeat bottom;
  background-size: cover;
  min-height: 100vh;
  height: 100%;
}
body {
  width: 100%;
  animation: fadeInAnimation ease-in 250ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  height: 100%;
  min-height: 100vh;
  font-weight: 300;
  font-size: 20px;
  color: #fff;
  line-height: 30px;
  padding: 0;
  margin: 0;
  background: linear-gradient(180deg, rgba(6, 10, 27, 0) 50.52%, #060a1b 100%);
  > div {
    background: rgba(6, 10, 27, 0.25);
  }
}
.App
{
  height: 100%;
  overflow:auto; 
 
}
.inner-element {
  transform: translateZ(40px) scale(0.9);
}

.menu {
  background: linear-gradient(
      90deg,
      rgba(5, 190, 254, 0.1755) 8.8%,
      rgba(41, 46, 69, 0.198) 49.1%
    ),
    rgba(41, 46, 69, 0.8);
  backdrop-filter: blur(10px);
}
.container {
  max-width: 920px;
}

a {
  color: #fff;
  transition: all 0.5s;
  &.active,
  &:hover {
    color: #04befe !important;
  }
  &.disabled-link {
    pointer-events: none;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "JetBrains Mono", monospace;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}